import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {IBatchDetails} from "../type";

export const BATCH_DETAILS_KEY = "batchDetails";

interface State {
    batchDetails?: IBatchDetails;
    error: string;
    batchDetailsLoading: boolean;
}

const initialState: State = {
    batchDetails: undefined,
    error: '',
    batchDetailsLoading: true,
};

const AppSlice = createSlice({
    name: BATCH_DETAILS_KEY,
    initialState,
    reducers: {
        setBatchDetailsLoading(state:State) {
            state.batchDetailsLoading = true;
        },
        setBatchDetails(state:State, action: PayloadAction<IBatchDetails>) {
            state.batchDetails = action.payload;
            state.batchDetailsLoading = false;
        },
        clearBatchDetails(state:State) {
            state.batchDetails = undefined
        }
    }
});

export const {setBatchDetailsLoading, setBatchDetails, clearBatchDetails} = AppSlice.actions;

const selectBatchDetailsSlice = (state: RootState) => state.batchDetails;

export const batchDetailSelector = createSelector(selectBatchDetailsSlice, (state) => ({
    batchDetails: state.batchDetails,
    batchDetailsLoading: state.batchDetailsLoading,
}));

export const batchDetailsReducer = AppSlice.reducer;

