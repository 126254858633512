import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";

export const ERROR_KEY = "error";

interface State {
    error?: string;
}

const initialState: State = {
    error: undefined,
};

const ErrorSlice = createSlice({
    name: ERROR_KEY,
    initialState,
    reducers: {
        setNetworkError(state:State, action: PayloadAction<string>) {            
            state.error = action.payload;
        },
        clearNetworkError(state:State) {
            state.error = undefined;
        }
    },
});

export const {setNetworkError, clearNetworkError} = ErrorSlice.actions;

const errorSlice = (state: RootState) => state.error;

export const errorSelector = createSelector(errorSlice, (state) => ({
    error: state?.error,
}));

export const errorReducer = ErrorSlice.reducer;

