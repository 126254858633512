import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {IBatchColumnName} from "../type";

export const EDIT_BATCH_KEY = "batchToEdit";

interface State {
    batchData?: IBatchColumnName;
    error: string;
    batchEditLoading: boolean;
}

const initialState: State = {
    batchData: undefined,
    error: '',
    batchEditLoading: true
};

const EditBatchSlice = createSlice({
    name: EDIT_BATCH_KEY,
    initialState,
    reducers: {
        setBatchEditLoading(state:State) {
            state.batchEditLoading = true;
        },
        setRowDataToEditBatch(state:State, action: PayloadAction<IBatchColumnName>) {       
            state.batchData = action.payload;
            state.batchEditLoading = false;
        },
        clearRowBatchData(state:State) {
            state.batchData = undefined
        }
    }
});

export const {setRowDataToEditBatch, setBatchEditLoading, clearRowBatchData} = EditBatchSlice.actions;

const selectEditBatchSlice = (state: RootState) => state.batchToEdit;

export const editBatchSelector = createSelector(selectEditBatchSlice, (state) => ({
    batchData: state.batchData,
    batchEditLoading: state.batchEditLoading,
}));

export const editBatchReducer = EditBatchSlice.reducer;

