import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { type PreloadedState, configureStore } from "@reduxjs/toolkit";
import { errorLoggerMiddleware } from "./middleware";
import { combinedReducer, rootReducer } from "./root-reducer";

export const middleware = [errorLoggerMiddleware];

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
    devTools: true,
  });
};

export const store = setupStore({});
// setupListeners(store.dispatch); // no need because not using RTK

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
