import styles from "./Loading.module.css";
import { LOADING_TEXT } from "../ComponentsConstants";

const Loading = ({ loadingText = LOADING_TEXT }) => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loading}>
        <div className={styles.loader}></div>
        <p>{loadingText}</p>
      </div>
    </div>
  );
};

export default Loading;
