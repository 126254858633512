import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n

  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: "en",
    fallbackNS: "translation",
    ns:"translation",
        backend: {
          loadPath: `${process.env.PUBLIC_URL} /locales/en/translation.json`
        }
  });

export default i18n;