import {IBatchColumnName, IError, IGridColumn, ITicketColumnName, ITrailer} from "../common/type";
import {CREATED_BY} from "../pages/Batches/BatchListConstant";
import React from "react";
import styles from '../common/css/Common.module.css';
import trailerStyles from '../pages/Overview/Trailers.module.css';
import {ErrorCodes, ErrorMessage} from "./ErrorConstants";
import {ERROR_ROUTE} from "./RoutesConstants";
import {setNetworkError} from "../common/slices/error-slice";
import {GridComparatorFn} from "@mui/x-data-grid";
import {BATCH_STATUS, PREFIX_TRAILER_GC_ID, REGEX, TRAILERS_STATE, UNITS} from "./Constants";

export const alphaNumericComparator: GridComparatorFn = (v1, v2) => {
    if (v1 === null) {
        return 1;
    }
    if (v2 === null) {
        return -1;
    }
    return v1.localeCompare(v2, 'en', {numeric: true});
}
export const dataGridStyles = {
    height: 600,
    width: 1360,
    "& .MuiDataGrid-root": {
        border: "none",
        marginRight: "3px !important",
        marginLeft: "4px !important"
    },
    "& .super-app-theme--header": {
        minHeight: "59px !important",
        backgroundColor: "rgba(217, 221, 223, 0.5);",
    },
    "& .super-app-theme--header:first-of-type": {
        paddingLeft: "45px",
    },
    "& .MuiDataGrid-columnHeaders": {
        minHeight: "60px !important",
        maxHeight: "60px !important",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: "var(--grayMedium)",
    },
    "& .MuiDataGrid-iconSeparator": {
        visibility: "hidden",
    },
    "& .MuiDataGrid-footerContainer": {
        border: "none",
        margin: "0px",
        marginTop: "20px",
    },
    "& .MuiDataGrid-selectedRowCount": {
        display: "none",
    },
    "& .MuiDataGrid-cell": {
        maxHeight: "72px !important",
        minHeight: "72px !important",
        borderBottom: "1px solid var(--lightGray) !important",
    },
    "& .MuiDataGrid-row": {
        maxHeight: "72px !important",
        minHeight: "72px !important",
        borderBottom: "1px solid var(--lightGray) !important",
    },
    "& .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
    },
    "& .Mui-selected": {
        backgroundColor: "var(--lightGray) !important",
    },
    "& .MuiDataGrid-iconButtonContainer": {
        visibility: "visible",
        margin: "10px",
    },
    "& .MuiDataGrid-menuIcon": {
        marginRight: "-3px !important"
    }
};

export const dataGridNoResultStyle = {
    fontWeight: 700,
    lineHeight: "24px",
    color: "var(--grayMedium)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    fontSize: "24px",
};

export const parseJwt = (token: any) => {
    if (token) {
        const base64Url = token?.split('.')[1];
        const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}
export const getErrorMessage = (error: string, entityName: string) => {
    if (error === ErrorCodes.NotFound) {
        switch (entityName) {
            case "batch":
                return ErrorMessage.BATCH_NOT_FOUND;
            case "batchDetails":
                return ErrorMessage.BATCH_DETAILS_NOT_FOUND;
            case "batchFlow":
                return ErrorMessage.BATCH_FLOW_NOT_FOUND;
            case "ticket" :
                return ErrorMessage.TICKET_NOT_FOUND;
            case "trailer" :
                return ErrorMessage.TRAILERS_NOT_FOUND;
            case "sites" :
                return ErrorMessage.SITES_NOT_FOUND;
            default :
                return ErrorMessage.DATA_NOT_FOUND;
        }
    } else if (error === ErrorCodes.AccessDenied) {
        return ErrorMessage.ACCESS_DENIED;
    } else if (error === ErrorCodes.UncaughtException) {
        return ErrorMessage.UNCAUGHT_EXCEPTION;
    } else {
        return error || ErrorMessage.UNCAUGHT_EXCEPTION;
    }
}


export const errorHandler = (error: IError, navigate: (arg0: string) => void, dispatch: (arg0: {
    payload: string;
    type: "error/setNetworkError";
}) => void, signIn: () => void, signOut: () => void, entityName: string) => {
    if (error?.errorCode === ErrorCodes.LoginRequired) {
        signIn();
    } else if (error?.errorCode === ErrorCodes.LogOutRequired) {
        signOut();
    } else {
        dispatch(
            setNetworkError(getErrorMessage(error?.errorCode, entityName))
        );
        navigate(ERROR_ROUTE);
    }
}

export const isObjectEmpty = (obj = {}) =>
    !obj || Object.keys(obj).length === 0;

export const getCreatedByStyle = (row: IBatchColumnName | ITicketColumnName) => {
    if (!row.isTrusted) {
        return `${styles.dataGridStyle} ${styles.untrusted}`
    } else if (row.createdBy.toUpperCase() !== CREATED_BY.SYSTEM) {
        return `${styles.dataGridStyle} ${styles.notSystem}`
    } else {
        return `${styles.dataGridStyle} ${styles.trusted}`
    }
};

export const getChipBackgroundColor = (createdBy: string) =>
    createdBy.toUpperCase() === CREATED_BY.SYSTEM ? 'var(--blackColor)' : 'var(--amberColor)';

export const createColumn = (
    field: string,
    headerName: string,
    minWidth: number,
    type: string,
    sortComparator: GridComparatorFn,
    renderCell: React.FC<any>,
    valueGetter?: (params: any) => any,
): IGridColumn => ({
    field,
    headerName,
    flex: 1,
    minWidth,
    type,
    sortComparator,
    headerClassName: "super-app-theme--header",
    renderCell,
    valueGetter
});

export const getOptionsWithIds = (optionArray: any) => {
    return optionArray.map((itm: any) => ({
        label: itm?.name || itm?.trailerGcId,
        value: itm?.id,
        id: itm?.id
    }));
};

export const isObjectValuesEmpty = (obj: any) => Object.values(obj).some((x) => x === null || x === "");


export const checkInput = (value: string, type: string) => {
    const patterns: { [key: string]: RegExp } = {
        numberOnly: REGEX.NUMBER,
        alphabetsWithSpecialCharacter: REGEX.ALPHABET_WITH_SPECIAL_CHARACTER
    };

    if (type in patterns) {
        const pattern = patterns[type];
        if (pattern.test(value)) {
            if (type === "numberOnly") {
                return value.replace(`${REGEX.NUMBER}/g`, "");
            } else if (type === "alphabetsWithSpecialCharacter") {
                return value.replace(`${REGEX.ALPHABET_WITH_SPECIAL_CHARACTER}/g`, "_");
            }
        }
    }
};

export const getStatusColor = (status: string | null) => {
    if (status === BATCH_STATUS[3]) {
        return 'var(--successColor)';
    } else if (status === BATCH_STATUS[2] || status === TRAILERS_STATE.COMMISSIONING || status === TRAILERS_STATE.IN_ACTIVE || status === TRAILERS_STATE.WORKSHOP) {
        return 'var(--grayDark)';
    } else {
        return 'var(--primaryColor)';
    }
}

export const getLastUpdatedChipCssClass = (status: string | null, lastUpdatedDateInUtc: string | null) => {
    if (status === TRAILERS_STATE.COMMISSIONING || status === TRAILERS_STATE.IN_ACTIVE || status === TRAILERS_STATE.WORKSHOP) {
        return `${trailerStyles.chipInActive}`;
    } else if (status === TRAILERS_STATE.UNKNOWN || !lastUpdatedDateInUtc) {
        return `${trailerStyles.chipWithNoData}`;
    } else {
        return `${trailerStyles.chipActive}`;
    }
}

export const getLastUpdatedCssClass = (status: string | null, lastUpdatedDateInUtc: string | null) => {
    if (status === TRAILERS_STATE.COMMISSIONING || status === TRAILERS_STATE.IN_ACTIVE || status === TRAILERS_STATE.WORKSHOP) {
        return `${trailerStyles.lastUpdatedContainer}`;
    } else if (!lastUpdatedDateInUtc || status === TRAILERS_STATE.UNKNOWN) {
        return `${trailerStyles.lastUpdatedContainerForNoData}`;
    } else {
        return `${trailerStyles.lastUpdatedContainer}`;
    }
}

export function toTitleCase(str: string) {
    const text = str.toLowerCase().split(' ');
    for (let i = 0; i < text.length; i++) {
        text[i] = text[i].charAt(0).toUpperCase() + text[i].slice(1);
    }
    return text.join(' ');
}

export const formatValueWithUnit = (value: number | string | null, unit: string) => {
    return `${value} ${unit}`;
}

export const formatMassWithUnit = (value: number | string, unit: string = UNITS.KG) => {
    return `${roundMass(value)} ${unit}`;
}

export const formatTrailerMass = (mass: number, unit: string = UNITS.KG) => {
    const nettoMass = mass < 140 ? 0 : mass - 140
    return `${formatMassWithUnit(mass, unit)} / ${formatMassWithUnit(nettoMass, unit)}`;
}

export const customSortForTrailers = (a: ITrailer, b: ITrailer) => {
    const aStartsWithGC = a.trailerGcId ? a.trailerGcId.toUpperCase().startsWith(PREFIX_TRAILER_GC_ID) : false;
    const bStartsWithGC = b.trailerGcId ? b.trailerGcId.toUpperCase().startsWith(PREFIX_TRAILER_GC_ID) : false;
    if (aStartsWithGC && !bStartsWithGC) {
        return -1;
    } else if (!aStartsWithGC && bStartsWithGC) {
        return 1;
    } else {
        const aTrailerGcIdId = a.trailerGcId ?? '';
        const bTrailerGcIdId = b.trailerGcId ?? '';
        return aTrailerGcIdId.localeCompare(bTrailerGcIdId);
    }
};

export const comparePressures = (primaryValue: any, secondaryValue: any) => {
    if (primaryValue !== null && primaryValue > -99 && secondaryValue !== null && secondaryValue > -99) {
        return primaryValue > secondaryValue ? Math.round(primaryValue) : Math.round(secondaryValue);
    } else if (primaryValue !== null && primaryValue > -99) {
        return Math.round(primaryValue);
    } else if (secondaryValue !== null && secondaryValue > -99) {
        return Math.round(secondaryValue);
    } else {
        return `-`;
    }
};

export const roundMass = (num: number | string) => +Number(num).toFixed(0);