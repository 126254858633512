import React, { createContext, useEffect, useMemo, ReactNode } from "react";

interface UserContextType {
  signIn: () => void;
  signOut: () => void;
}

export const UserContext = createContext<UserContextType>({
  signIn: () => { },
  signOut: () => { },
});

interface UserProviderProps {
  children: ReactNode;
  signIn: () => void;
  signOut: () => void;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children, signIn, signOut }) => {
  useEffect(() => { }, []);

  const providerValue = useMemo(() => ({ signIn, signOut }), [signIn, signOut]);

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};
