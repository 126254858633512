import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import { IFlowDataProps } from "../type";

export const BATCH_FLOW_KEY = "batchFlow";

interface State {
    batchFlow: IFlowDataProps[];
    error: string;
    batchFlowLoading: boolean;
}

const initialState: State = {
    batchFlow: [],
    error: '',
    batchFlowLoading: true,
};

const AppSlice = createSlice({
    name: BATCH_FLOW_KEY,
    initialState,
    reducers: {
        setFlowLoading(state:State) {
            state.batchFlowLoading = true;
        },
        setBatchFlow(state:State, action: PayloadAction<IFlowDataProps[]>) {
            state.batchFlow = action.payload;
            state.batchFlowLoading = false;
        },
        clearBatchFlow(state:State) {
            state.batchFlow = []
        }
    }
});

export const {setFlowLoading, setBatchFlow, clearBatchFlow} = AppSlice.actions;

const selectBatchFlowSlice = (state: RootState) => state.batchFlow;

export const batchFlowSelector = createSelector(selectBatchFlowSlice, (state) => ({
    batchFlow: state.batchFlow,
    batchFlowLoading: state.batchFlowLoading,
}));

export const batchFlowReducer = AppSlice.reducer;

