import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {ITrailer} from "../type";

export const TRAILER_KEY = "trailers";

interface State {
    trailers: ITrailer[];
    error: string;
    trailerLoading: boolean;
}

const initialState: State = {
    trailers: [],
    error: '',
    trailerLoading: true,
};

const TrailerSlice = createSlice({
    name: TRAILER_KEY,
    initialState,
    reducers: {
        setTrailersLoading(state: State) {
            state.trailerLoading = true;
        },
        setTrailers(state: State, action: PayloadAction<ITrailer[]>) {
            state.trailers = action.payload;
            state.trailerLoading = false;
        },
    },
});

export const {setTrailersLoading, setTrailers} = TrailerSlice.actions;

const selectTrailersSlice = (state: RootState) => state.trailers;

export const trailersSelector = createSelector(selectTrailersSlice, (state) => ({
    trailers: state.trailers,
    trailerLoading: state.trailerLoading,
}));

export const trailersReducer = TrailerSlice.reducer;

