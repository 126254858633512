import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ISite } from "../type";

export const SITES_KEY = "sites";

interface State {
  sites: ISite[];
  error:string;
  sitesLoading: boolean;
}

const initialState: State = {
  sites: [],
  error:'',
  sitesLoading: true,
};

const SitesSlice = createSlice({
  name: SITES_KEY,
  initialState,
  reducers: {
    setSitesLoading(state:State){
        state.sitesLoading = true;
    },
    setSites(state:State, action: PayloadAction<ISite[]>) {
      state.sites = action.payload;
      state.sitesLoading = false;
    },
  },
});

export const { setSitesLoading,setSites } = SitesSlice.actions;

const selectSitesSlice = (state: RootState) => state.sites;

export const sitesSelector = createSelector(selectSitesSlice, (state) => ({
    sites: state.sites,
    sitesLoading: state.sitesLoading,
}));

export const sitesReducer = SitesSlice.reducer;

