import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {ITicketColumnName} from "../type";

export const TICKET_KEY = "ticket";

interface State {
    ticket: ITicketColumnName[];
    rowData?: ITicketColumnName;
    error: string;
    ticketLoading: boolean;
}

const initialState: State = {
    ticket: [],
    rowData: undefined,
    error: '',
    ticketLoading: true,
};

const AppSlice = createSlice({
    name: TICKET_KEY,
    initialState,
    reducers: {
        setTicketLoading(state: State) {
            state.ticketLoading = true;
        },
        setTicket(state: State, action: PayloadAction<ITicketColumnName[]>) {
            state.ticket = action.payload;
            state.ticketLoading = false;
        },
        setRowClickedData(state: State, action: PayloadAction<ITicketColumnName>) {
            state.rowData = action.payload;
            state.ticketLoading = false;
        },
        clearTicket(state: State, action: PayloadAction<string>) {
            state.ticket = [];
            state.ticketLoading = false;
            state.error = action.payload;
        },

        clearRowData(state: State) {
            state.rowData = undefined
        }
    },
});

export const {setTicketLoading, setTicket, clearTicket, setRowClickedData, clearRowData} = AppSlice.actions;

const selectTicketSlice = (state: RootState) => state.ticket;

export const ticketSelector = createSelector(selectTicketSlice, (state) => ({
    ticket: state.ticket,
    rowData: state.rowData,
    ticketLoading: state.ticketLoading,
    error: state.error
}));

export const ticketReducer = AppSlice.reducer;

