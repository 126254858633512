import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

export const errorLoggerMiddleware: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if (action?.payload?.data?.message?.includes("Network request failed")) {
      console.error("Network error");
    }
  }

  return next(action);
};
