import barOne from '../assets/Icons/CylinderBarsIcons/BarOne.svg';
import barTwo from '../assets/Icons/CylinderBarsIcons/BarTwo.svg';
import barThree from '../assets/Icons/CylinderBarsIcons/BarThree.svg';
import barFour from '../assets/Icons/CylinderBarsIcons/BarFour.svg';
import barFive from '../assets/Icons/CylinderBarsIcons/BarFive.svg';
import barSix from '../assets/Icons/CylinderBarsIcons/BarSix.svg';
import barSeven from '../assets/Icons/CylinderBarsIcons/BarSeven.svg';
import barEight from '../assets/Icons/CylinderBarsIcons/BarEight.svg';
import barNine from '../assets/Icons/CylinderBarsIcons/BarNine.svg';
import barAllActive from '../assets/Icons/CylinderBarsIcons/BarAllActive.svg';

import battery from '../assets/Icons/BatteryIcons/Battery.svg';
import batteryError from '../assets/Icons/BatteryIcons/BatteryError.svg';
import batteryWarning from '../assets/Icons/BatteryIcons/BatteryWarning.svg';
import {FilterItem} from "../common/type";

export const TRANS_KEYS = {
    translation: "translation",
    OVERVIEW: "overview",
    BATCHES: "batch",
    TICKET: "ticket",
    ALERT: "alert",
    COMMON: 'common',
    LEFT_MENU_FOOTER: 'left_menu_footer',
    EXPANDABLE_DETAILS: 'expandable_batch_details'
}

export const PAGE_SIZE = 12;

export const responseStatusCodes = {
    success: 200,
    unAuthorized: 401,
    notFound: 400,
    AccessDenied: 403,
    internalServerError: 500
}

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const REGEX = {
    NUMBER: /^\d+$/,
    ALPHABET_WITH_SPECIAL_CHARACTER: /^[a-zA-Z\u0080-\uFFFF0-9_,. -]{0,250}$/,
}

export const PREFIX_TRAILER_GC_ID = 'GC';

export const UNITS = {
    PERCENTAGE: "%",
    KG: "kg",
    DEGREE_CELSIUS: "°C",
    BAR: "Bar"
}

export const TRAILER_CYLINDER_CAPACITY = [
    {name: barOne, range: {min: 0, max: 30}},
    {name: barTwo, range: {min: 31, max: 60}},
    {name: barThree, range: {min: 61, max: 90}},
    {name: barFour, range: {min: 91, max: 120}},
    {name: barFive, range: {min: 121, max: 150}},
    {name: barSix, range: {min: 151, max: 180}},
    {name: barSeven, range: {min: 181, max: 210}},
    {name: barEight, range: {min: 211, max: 240}},
    {name: barNine, range: {min: 241, max: 270}},
    {name: barAllActive, range: {min: 271, max: Infinity}},
];

export const BATTERY_HEALTH = [
    {threshold: 15, color: "var(--redColor)", status: batteryError},
    {threshold: 30, color: "var(--amberColor)", status: batteryWarning},
    {threshold: Infinity, color: "var(--blackColor)", status: battery}
];

export const TRAILERS_STATE = {
    ON_THE_WAY: "OnTheWay",
    IN_TRANSIT: "In Transit",
    CONNECTED: "Connected",
    DISCONNECTED: "Disconnected",
    WAITING: "Waiting",
    FILLING: "Filling",
    COMMISSIONING: "Commissioning",
    DISPENSING: "Dispensing",
    IDLE: "Idle",
    PARKED: 'Parked',
    IN_ACTIVE: 'InActive',
    UNKNOWN: "Unknown",
    WORKSHOP: "Workshop",
}

export enum BATCH_STATUS {
    ACTIVE = 1,
    PAUSED = 2,
    COMPLETED = 3,
    ERROR = 4
}

export enum ALERT_TYPE {
    MISSING_DATA = "Missing data",
    PRESSURE_TOO_HIGH_UNDER_TRANSIT = "Pressure to high under transit",
    TEMPERATURE_SENSOR_DEFECT = "Temperature sensor is defect",
    PRESSURE_SENSOR_DEFECT = "Pressure sensor is defect",
    BATTERY_NOT_CHARGING = "Battery is not charging",
    LOW_BATTERY = "Low battery detected",
    PNEUMATIC_SWITCH = "Pneumatic switch",
    PNEUMATIC_SWITCH_ALWAYS_OFF = "Pneumatic switch is always off",
    MASS_DIFFERENCE_ALERT = "Mass difference detected between batches and trailer",
    TRAILER_CAPACITY_EXCEEDED = "Trailer capacity exceeded"
}

export const TrailerFilterItems: FilterItem[] = [
    {title: 'Active Trailers', id: 'active'},
    {title: 'All Trailers', id: 'all'},
]

export const TicketFilterItems: FilterItem[] = [
    {title: 'All Tickets', id: 'all'},
    {title: 'Trusted Tickets', id: 'trusted'},
    {title: 'UnTrusted Tickets', id: 'unTrusted'},
]

export const BatchFilterItems: FilterItem[] = [
    {title: 'All Batches', id: 'all'},
    {title: 'Trusted Batches', id: 'trusted'},
    {title: 'UnTrusted Batches', id: 'unTrusted'},
]

export const ICONS = {
    ADDITION: '+',
    SUBTRACT: '-'

}