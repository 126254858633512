import React, { useEffect, useCallback, useState, ReactNode } from "react";
import {
  useIsAuthenticated,
  useMsal,
  AuthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { UserProvider } from "./UserProvider";
import { loginRequest } from "../../authConfig";
import { API } from "../../utils/API";
import { HOME_ROUTE } from "../../utils/RoutesConstants";

interface AuthorizedPageProps {
  children: ReactNode;
}

export const AuthorizedPage: React.FC<AuthorizedPageProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  const { instance: msalInstance, inProgress } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  const signIn = useCallback(async () => {
    try {
      if (inProgress === InteractionStatus.None) {
        const res = await msalInstance.acquireTokenSilent(loginRequest);

        API.setToken(res.accessToken);
        setIsAuthorized(true);
      }
    } catch (error) {
      await msalInstance.loginRedirect({
        scopes: loginRequest.scopes,
      });
    }
  }, [msalInstance, inProgress]);

  const signOut = useCallback(async () => {
    await msalInstance.logoutRedirect({
      postLogoutRedirectUri: HOME_ROUTE,
    });
  }, [msalInstance]);

  useEffect(() => {
    const token = API.getToken();
    if(inProgress === InteractionStatus.None) {
        if (!token || !isAuthenticated){
            signIn();
        }
    }
  }, [isAuthenticated, inProgress, msalInstance, signIn]);

  return (
    <>
      {isAuthorized && (
        <AuthenticatedTemplate>
          <UserProvider signIn={signIn} signOut={signOut}>
            {children}
          </UserProvider>
        </AuthenticatedTemplate>
      )}
    </>
  );
};
