export const ErrorCodes = {
  LoginRequired: "LoginRequired",
  NotFound: "NotFound",
  AccessDenied: "AccessDenied",
  UncaughtException: "UncaughtException",
  LogOutRequired: "LogOutRequired",
  BadRequest: "BadRequest"
};
export const BTN_TRY_AGAIN_TXT = "Try again";
export const ErrorMessage = {
  PROFILE_NOT_FOUND_ERROR_MESSAGE:
    'Your profile cannot be found. Please get in touch with "Mikkel Damgaard" to be onboarded to HyOPs.',
  ACCESS_DENIED: `You don't have permission to access this page`,
  LOGIN_REQUIRED: "Login Required",
  UNCAUGHT_EXCEPTION: "Something went wrong, Please try again later",
  DATA_NOT_FOUND: "Requested data not found",
  PAGE_NOT_FOUND: "404 - Page Not Found",
  BATCH_NOT_FOUND:'Batch not found',
  BATCH_DETAILS_NOT_FOUND:'Batch Details not found',
  BATCH_FLOW_NOT_FOUND:'Batch flow not found',
  TICKET_NOT_FOUND:'Ticket not found',
  TRAILERS_NOT_FOUND:'Trailers not found',
  SITES_NOT_FOUND:'Sites not found'
};