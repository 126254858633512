import {ConfirmContextProvider} from "../../context/confirmContext";
import LandingPage from "./LandingPage";

const MainLayout = () => {

    return (
        <ConfirmContextProvider>
            <LandingPage/>
        </ConfirmContextProvider>
    );
};

export default MainLayout;
