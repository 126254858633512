import React, { createContext, useState, useMemo } from "react";

type ConfirmContextValue = {
  isNeedToConfirm: boolean;
  setIsNeedToConfirm: (value: boolean) => void;
};

export const ConfirmContext = createContext<ConfirmContextValue | undefined>(undefined);

type ConfirmContextProviderProps = {
  children: React.ReactNode;
};

export const ConfirmContextProvider = ({ children }: ConfirmContextProviderProps) => {

  const [isNeedToConfirm, setIsNeedToConfirm] = useState(false);

  const value = useMemo(
    () => ({
      isNeedToConfirm,
      setIsNeedToConfirm,
    }),
    [isNeedToConfirm]
  );

  return <ConfirmContext.Provider value={value}>{children}</ConfirmContext.Provider>;
};


